<script setup lang="ts">
const props = defineProps( {
  placeholder: {
    required: true,
    type: String
  },
  type: {
    required: true,
    type: String
  },
});
</script>

<template>
  <v-text-field
    density="compact"
    variant="solo"
    bg-color="input"
    class="tenant-text-field"
    v-bind="props" />
</template>

<style scoped lang="scss">
.tenant-text-field {
  height: 40px;
}
</style>
