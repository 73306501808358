/**
 * @file @/router/items.ts
 * @description App Routes
 */

import { createRouter, createWebHistory } from 'vue-router';

import AuthRoutes from '@/router/auth';
import { PublicRoutes, PrivateRoutes } from '@/router/routes';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Main/MainLayout.vue'),
      children: [...PublicRoutes, ...PrivateRoutes, AuthRoutes],
    },
    {
      // All Routes fall into the main path and main route layout
      path: '/:pathMatch(.*)*',
      component: () => import('@/layouts/Main/MainLayout.vue'),
      children: [
        {
          path: 'live',
          component: () => import('@/views/maintenance/Error404NotFound.vue'),
        },
      ],
    },
  ],
});

export default router;
