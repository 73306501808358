import { httpClient } from '@singlesoftware/single-http';
import AxiosMockAdapter from 'axios-mock-adapter';

import type {
  AsymmetricHeadersMatcher,
  AsymmetricRequestDataMatcher,
  CustomMockAdapterProps,
} from '@/@types/mock/mockAdapter.types';
import { commomResponseFields } from '@/api/mocks/mockCommoms';

export const mockApiPrefix = '/mock-api';

class CustomMockAdapter
  extends AxiosMockAdapter
  implements CustomMockAdapterProps
{
  constructor(axiosInstance: any, options?: any) {
    super(axiosInstance, options);
  }

  onGetWithPrefix(
    matcher?: string | RegExp,
    body?: string | AsymmetricRequestDataMatcher,
    headers?: AsymmetricHeadersMatcher,
  ) {
    return this.onGet(mockApiPrefix.concat(matcher as string), body, headers);
  }

  onPostWithPrefix(
    matcher?: string | RegExp,
    body?: string | AsymmetricRequestDataMatcher,
    headers?: AsymmetricHeadersMatcher,
  ) {
    return this.onPost(mockApiPrefix.concat(matcher as string), body, headers);
  }

  onPutWithPrefix(
    matcher?: string | RegExp,
    body?: string | AsymmetricRequestDataMatcher,
    headers?: AsymmetricHeadersMatcher,
  ) {
    return this.onPut(mockApiPrefix.concat(matcher as string), body, headers);
  }

  onDeleteWithPrefix(
    matcher?: string | RegExp,
    body?: string | AsymmetricRequestDataMatcher,
    headers?: AsymmetricHeadersMatcher,
  ) {
    return this.onDelete(
      mockApiPrefix.concat(matcher as string),
      body,
      headers,
    );
  }
}

const mock = new CustomMockAdapter(httpClient.getInstance(), {
  delayResponse: 500,
});

/**
 * Mocks an error response with the given code and message.
 * @param code
 * @param message
 */
export function mockError(code: number, message: string) {
  mock.onAny().reply(code, { message });
}

/**
 * Mocks a delay response with the given delay and response.
 * @param delay
 * @param response
 */
export function mockDelay(delay: number, response: any) {
  mock.onAny().reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, delay);
    });
  });
}

/**
 * Mocks a paginated response with the given data.
 * @param data
 */
export function mockPaginatedResponse<T>(data: T[]) {
  return {
    data,
    total: data.length,
    ...commomResponseFields,
  };
}

export default mock;
