import { Locales } from '@/constants/Locale';
import en from '@/locales/langs/en.json';
import es from '@/locales/langs/es.json';
import pt from '@/locales/langs/pt.json';

// Type-define 'en' as the master schema for all messages.
export type MessageSchema = typeof en;

const messages = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.PT]: pt,
};

export default messages;
