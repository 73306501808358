export type Handler = (...args: any) => void;

export interface ICallbackList {
  [id: string]: Handler;
}

export type EventRecord = Record<string, ICallbackList>;

export interface IEventBus {
  emit<T extends any[]>(eventName: string, ...args: T): void;
  on(eventName: string, handler: Handler): void;
  off(eventName: string, handler: Handler): void;
  clear(eventName: string): void;
}

class EventBus implements IEventBus {
  private _eventObject: EventRecord;
  private _callbackId: number;

  constructor() {
    // initialize event list
    this._eventObject = {};
    // id of the callback function list
    this._callbackId = 0;
  }

  // publish event
  emit<T extends any[]>(eventName: string, ...args: T): void {
    // Get all the callback functions of the current event
    const callbackObject = this._eventObject[eventName];

    const isTest = import.meta.env.MODE === 'test';

    if (!callbackObject && !isTest)
      return console.warn(eventName + ' not found!');

    // execute each callback function
    for (const id in callbackObject) {
      // pass parameters when executing
      callbackObject[id](...args);
    }
  }

  // Subscribe to events
  on(eventName: string, handler: Handler): void {
    // initialize this event
    if (!this._eventObject[eventName]) {
      // Use object storage to improve the efficiency of deletion when logging out the callback function
      this._eventObject[eventName] = {};
    }

    const id = this._callbackId++;

    // store the callback function of the subscriber
    // callbackId needs to be incremented after use for the next callback function
    this._eventObject[eventName][id] = handler;
  }

  // remove event listener from
  off(eventName: string, handler: Handler): void {
    // If no event name is provided, all events are cleared by default
    if (!eventName) {
      this._eventObject = {};
      return;
    }

    for (const key in this._eventObject) {
      if (this._eventObject[eventName][key] === handler) {
        delete this._eventObject[eventName][key];
      }
    }
    // clear the specified event
    delete this._eventObject[eventName];
  }

  // clear event
  clear(eventName: string): void {
    // If no event name is provided, all events are cleared by default
    if (!eventName) {
      this._eventObject = {};
      return;
    }

    // clear the specified event
    delete this._eventObject[eventName];
  }
}

export default class EventBusFactory {
  private static _eventBus?: EventBus;

  public static getEventBus(): EventBus {
    // first initialization
    if (this._eventBus == undefined) {
      this._eventBus = new EventBus();
    }

    // Subsequent to directly take a unique instance each time, keep the global singleton
    return this._eventBus;
  }
}
