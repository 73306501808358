/**
 * @file plugins/items.ts
 * Automatically included in `./src/main.ts`
 */
import type { App } from 'vue';

import { createPinia } from 'pinia';
import { createMetaManager } from 'vue-meta';
import mask from 'vue-the-mask';
import Toast from 'vue-toastification';

import { loadFonts } from '@/plugins/webfontloader';
import router from '@/router';

import i18n from './i18n';
import vuetify from './vuetify';

/**
 * Register all plugins in the main application instance.
 * @param app Vue app
 */
export function registerPlugins(app: App) {
  /* Web Fonts Loader */
  loadFonts();

  /* App Routes */
  app.use(router);

  /* Pages and Views Metadata */
  app.use(createMetaManager());

  /* UI Framework */
  app.use(vuetify);

  /* State Management */
  app.use(createPinia());

  /* Vue mask */
  app.use(mask);

  /* Internationalization */
  app.use(i18n);

  /* Toast elements */
  app.use(Toast, {
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
  });
}
