<template>
  <v-dialog
      :model-value="modelValue"
      @update:model-value="value => emits('update:modelValue', value)"
      class="modal">
      <ForgotPassword @close="emits('update:modelValue', false)"/>
  </v-dialog>
</template>

<script setup lang="ts">
import ForgotPassword from './ForgotPassword.vue';

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

const emits = defineEmits(['update:modelValue']);

</script>

<style scoped lang="scss">
a:hover { cursor: pointer }

.modal {
  max-width: 450px;
  width: 90%;
}

.modal-wrapper {
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-neutral));
  position: relative;
  padding: 6px 12px;
  text-align: center;
  border-radius: 5px;
}

.modal-wrapper h1 {
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(var(--v-theme-neutral));
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px 0 25px 0;
}

.modal-wrapper h2 {
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 13px;
  font-weight: 300;
  letter-spacing: 1.2px;
}

.modal-wrapper img {
  max-width: 200px;
  margin-bottom: 10px;
}

.close-icon {
  background-color: #ffffff29;
  color: rgb(var(--v-theme-neutral));
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  width: 25px;
  height: 25px;
}

.login-form-wrapper {
  text-align: center;
  color: rgb(var(--v-theme-neutral));
  text-decoration: none;
}

.login-form-wrapper p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.65px;
  text-align: center;
}

.send-button {
  width: 158px;
  height: 43px;
  background-color: rgb(var(--v-theme-primary));
  font-size: 16px;
  font-weight: bold;
  color: rgb(var(--v-theme-background));
  letter-spacing: 0.8px;
}

.key-icon {
  font-size: 46px;
  margin-top: 15px;
}

.alert {
  border: 1px solid rgb(var(--v-theme-success));
  font-size: 15px;
}
</style>
