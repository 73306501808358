import type { RouteRecordRaw } from 'vue-router';

export const UserRoute: RouteRecordRaw = {
  path: '/user',
  name: 'User',
  children: [
    {
      path: '/user',
      name: 'Profile',
      component: () => import('@/views/private/profile/UserProfile.vue'),
    },
  ],
};
