/**
 * plugins/i18n.ts
 *
 * i18n documentation: https://vue-i18n.intlify.dev/guide/migration/vue3.html
 */
import { createI18n } from 'vue-i18n';

import { LOCALE_KEYS, Locales } from '@/constants/Locale';
import messages, { MessageSchema } from '@/locales/messages';
import { localeServices } from '@/services/locale';

const i18n = createI18n<[MessageSchema], string>({
  // Must use legacy: false to use Composition API (ref, computed, etc). See
  // https://vue-i18n.intlify.dev/guide/advanced/composition.html#basic-usage
  legacy: false,

  // Refer a global scope Composer instance to VueI18n instance.
  globalInjection: true,

  locale: localeServices.getLocale(),
  fallbackLocale: Locales.EN,
  availableLocales: LOCALE_KEYS,

  silentTranslationWarn: true,
  silentFallbackWarn: true,

  messages: messages,

  numberFormats: {
    [Locales.EN]: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
    },
    [Locales.ES]: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
      },
    },
    [Locales.PT]: {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
      },
    },
  },
});

export default i18n;
