import type { RouteRecordRaw } from 'vue-router';

export const RegisterRouter: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: 'Register',
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        name: 'RegisterForm',
        component: () =>
          import('@/views/public/auth/forms/register/RegisterUser.vue'),
      },
    ],
  },
];

export default RegisterRouter;
