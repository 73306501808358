import type { RouteRecordRaw } from 'vue-router';

export const DepositRoute: RouteRecordRaw = {
  path: '/user/deposit',
  name: 'deposit',
  component: () => import('@/views/private/depositPage.vue'),
  redirect: '/',
  children: [
    {
      path: '/user/deposit',
      name: 'deposit',
      component: () => import('@/views/private/depositPage.vue'),
    },
  ],
};
