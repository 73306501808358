<template>
  <v-app :theme="theme.getThemeByBrand()">
    <RouterView />
  </v-app>
  <LoginModal v-model="loginDialog" />
  <ForgotPasswordModal v-model="forgotPasswordDialog" />
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, onErrorCaptured, ref } from 'vue';

import { POSITION, useToast } from 'vue-toastification';


import FeedbackService from '@/services/Feedback.service';
import { useAuthStore } from '@/stores/auth/auth.store';
import useThemeStore from '@/stores/theme/theme.store';
import EventBus from '@/utils/classes/EventBus';
import ForgotPasswordModal from '@/views/public/auth/forgotPassword/ForgotPasswordModal.vue';
import LoginModal from '@/views/public/auth/login/LoginModal.vue';

// Auth store
const authStore = useAuthStore();

// Event bus
const bus = EventBus.getEventBus();

// Vuetify theme
const theme = useThemeStore();

// Toast object for messages
const toast = useToast();

/**
 * Login Section
 */
const loginDialog = ref(false);

/**
 * Register Section
 */
const registerDialog = ref(false);
function onRegister() {
  registerDialog.value = true;
}

/**
 * Forgot password Section
 */
const forgotPasswordDialog = ref(false);
function onForgotPassword() {
  forgotPasswordDialog.value = true;
}

/**
 * Declare functions for feedbacks event listerners
 */
function onError(err: Error) {
  toast.clear();
  toast.error(err.message, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000
  });
}

function onWarn(warnMsg: string) {
  toast.clear();
  toast.warning(warnMsg, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000
  });
}

function onInfo(infoMsg: string) {
  toast.clear();
  toast.info(infoMsg, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000
  });
}

function onSuccess(successMsg: string) {
  toast.success(successMsg, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 5000
  });
}

/**
 * Lifecycle callbacks
 */
// Attach event bus listeners before moung
onBeforeMount(() => {
  bus.on('error', onError);
  bus.on('warn', onWarn);
  bus.on('info', onInfo);
  bus.on('success', onSuccess);

  bus.on('register', onRegister);
  bus.on('forgotPassword', onForgotPassword);

  if (authStore.isAuthenticated && !authStore.user) {
    authStore.silentRefresh();
  }
});

// Dettach event listeners on Unmount
onBeforeUnmount(() => {
  bus.off('error', onError);
  bus.off('warn', onWarn);
  bus.off('info', onInfo);
  bus.off('success', onSuccess);

  bus.off('register', onRegister);
  bus.off('forgotPassword', onForgotPassword);
});

// Set the callback for capture any error from application tree and pass it
// throughout Feedback flow
onErrorCaptured((error) => {
  FeedbackService.onError(error);
  return false;
});
</script>
