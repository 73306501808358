import type { RouteRecordRaw } from 'vue-router';

export const WalletRouter: RouteRecordRaw = {
  path: '/wallet',
  name: 'Wallet',
  component: () => import('@/views/private/WalletPage.vue'),
  redirect: '/',
  children: [
    {
      path: '/wallet',
      name: 'Wallet',
      component: () => import('@/views/private/WalletPage.vue'),
    },
  ],
};
