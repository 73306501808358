import type { RouteRecordRaw } from 'vue-router';

export const BetHistoryRoute: RouteRecordRaw = {
  path: '/user/bet-history',
  name: 'Bet History',
  meta: {
    title: 'Bet History',
    requiresAuth: false,
  },
  redirect: '/',
  children: [
    {
      path: '',
      name: 'Bet History',
      component: () => import('@/views/private/user/betHistory/betHistory.vue'),
    },
  ],
};
