import type { RouteRecordRaw } from 'vue-router';

export const WithdrawRoute: RouteRecordRaw = {
  path: '/withdraw',
  name: 'Withdraw',
  component: () => import('@/views/private/withdraw/WithdrawPage.vue'),
  redirect: '/',
  children: [
    {
      path: '/withdraw',
      name: 'Withdraw',
      component: () => import('@/views/private/withdraw/WithdrawPage.vue'),
    },
  ],
};
