import { ref } from 'vue';

import { defineStore } from 'pinia';

import type { ThemesNames } from '@/@types/theme';
import { useEnvironmentStore } from '@/stores/environment/environment.store';
import { themeConfig } from '@/themes';

export const useThemeStore = defineStore('theme', () => {
  const actTheme = ref<ThemesNames>(themeConfig.actTheme);
  const fontTheme = ref(themeConfig.fontTheme);

  function setActTheme(theme: ThemesNames) {
    actTheme.value = theme;
    // Save to local storage
  }

  function setFontTheme(font: string) {
    fontTheme.value = font;
    // Save to local storage
  }

  function getThemeByBrand() {
    const env = useEnvironmentStore();
    return env.selected_BRAND.toLowerCase();
  }

  return {
    actTheme,
    fontTheme,
    setActTheme,
    setFontTheme,
    getThemeByBrand,
  };
});

export default useThemeStore;
