import mock from '@/api/mocks/mockAdapter';
import type { LoginRes } from '@/api/models/auth.model';

const loginMockRes: LoginRes = {
  token: 'exemplo de token',
  token_expires_in: 2100,
  token_issued_at: new Date(),
  user: {
    name: 'Vandré dos Santos',
    username: 'vandre',
    birthdate: 'token',
    balance: '900.12',
    email: 'exemplo@email.com',
    phone: '+55 91 99000-0000',
  },
};

mock.onPostWithPrefix('/auth/login').reply(() => {
  return [200, { ...loginMockRes, token_issued_at: new Date() }];
});

mock.onPostWithPrefix('/auth/logout').reply(() => {
  return [200, {}];
});

mock.onPostWithPrefix('/auth/refresh').reply(() => {
  return [200, { ...loginMockRes, token_issued_at: new Date() }];
});
