import SingleHTTP from '@singlesoftware/single-http';

import { mockApiPrefix } from '@/api/mocks/mockAdapter';
import FeedbackServices from '@/services/Feedback.service';
import { useAuthStore } from '@/stores/auth/auth.store';

export default class SingleHTTPFactory {
  private static _singleHttp?: SingleHTTP;
  public static getSingleHTTP(): SingleHTTP {
    // First initialization
    if (this._singleHttp == undefined) {
      const authStore = useAuthStore();
      this._singleHttp = new SingleHTTP({
        baseURL: 'https://stg.1xpix.dev/api/v1', // TODO change this later to env api variable
        testMode: false,
        mockPrefix: mockApiPrefix,
        checkAuthentication: () => {
          return authStore.isAuthenticated;
        },
        onFeedback: {
          onInfo: FeedbackServices.onInfo,
          onWarn: FeedbackServices.onWarn,
          onSuccess: FeedbackServices.onSuccess,
          onError: FeedbackServices.onError,
        },
        customErrorHandlers: {
          401: () => {
            authStore.logout();
          },
        },
      });
    }

    // Subsequent calls return the global singleton
    return this._singleHttp;
  }
}
