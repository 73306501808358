import type { RouteRecordRaw } from 'vue-router';

import RegisterRouter from '@/router/auth/routes/register';

const AuthRoutes: RouteRecordRaw = {
  path: '/auth',
  name: 'Auth',
  meta: {
    requiresAuth: false,
  },
  component: () => import('@/layouts/Main/MainLayout.vue'),
  redirect: 'auth/register',
  children: [...RegisterRouter],
};

export default AuthRoutes;
