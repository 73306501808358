import { ThemeColors } from '@/@types/theme';
import {
  AccordionCustomColors,
  BalanceTopColors,
  BaseLayoutColors,
  BetslipColors,
  ButtonsColors,
  CasinoFavoriteColors,
  FeedEventColors,
  FooterColors,
  FormRegisterColors,
  FormsColors,
  GlobalColors,
  LMTColors,
  MenuWalletUserColors,
  ModalCalendarFilterColors,
  ModalColors,
  ModalLoginColors,
  ModalSearchResultColors,
  MyBonusColors,
  PrimaryColors,
  ProfileUserColors,
  StepRegisterColors,
  TablesColors,
  ToggleComponentColors,
  WeekDaysFilterColors,
} from '@/@types/theme/colors.types';

const primary: PrimaryColors = {
  background: '#0C395E',
  surface: '#092C44',
  primaryDark: '#207D3F',
  primary: '#207D3F',
  secondary: '#5D5D5D',
  tertiary: '#3E3E3E',
  quaternary: '#3E3E3E',
  quintenary: '#0D1119',
  active: '#DBC605',
  inactive: '#8F8F8F',
  inactiveText: '#8F8F8F',
  textOpacity: '#9EAFBE',
  textSecondary: '#F5F5F5',
  textTertiary: '#FAFAFA',
  textInputRegister: '#9EAFBE',
  success: '#DBC605',
  error: '#FF4A4A',
  info: '#2DA5FF',
  warning: '#ff8800',
  neutral: '#FFFFFF',
  bgHeaderAccordionEvents: '#207D3F',
  shadowHeaderAccordionEvents: '#3e3e3e',
  chevronAccordionColor: '#71AE95',
  scrollbarColor: '#3E3E3E',
  scrollbarColorDesktop: '#3E3E3E',
  messageSuccess: '#699719',
};

const global: GlobalColors = {
  bodyBg: '#5D5D5D',
  bodyText: '#FFFFFF',
};

const baseLayout: BaseLayoutColors = {
  topBarBg: '#207D3F',
  menuMobileBg: '#207D3F',
  bottomNavigationBg: '#207D3F',
  bottomNavigationShadow: '#000000',
};

const buttons: ButtonsColors = {
  buttonBg: '#DBC605',
  buttonLoginBg: '#346551',
  buttonTextColor: '#000000',
  buttonTextColorPromotion: '#346551',
  buttonBgInactive: '#8F8F8F',
  buttonTextColorInactive: '#D1D1D1',
  buttonWalletLogoutBg: '#1C2331',
  buttonWalletLogoutShadow: '#0D1119',
};

const forms: FormsColors = {
  inputBg: '#3E3E3E',
  inputSearchBg: '#346551',
  inputTextColor: '#8F8F8F',
  inputIconColor: '#8F8F8F',
  pink: '#9A1FBF',
  goldAlet: '#E5AC00',
  lightsuccess: '#9EAFBE',
};

const toggleComponent: ToggleComponentColors = {
  toggleChatBg: '#346551',
  toggleChatBgInner: '#034D33',
  toggleChatIcon: '#DBC605',
  toggleBonusBg: '#346551',
  toggleBonusBgInner: '#034D33',
  toggleBonusBgDesktop: '#346551',
  toggleBonusBgDesktopInner: '#034D33',
  toggleBonusTextColor: '#FFFFFF',
  toggleBonusIcon: '#DBC605',
};

const stepRegister: StepRegisterColors = {
  stepRegisterActive: '#207D3F',
  stepRegisterInactive: '#5D5D5D',
  stepRegisterTextActive: '#FFFFFF',
  stepRegisterTextInactive: '#8F8F8F',
  stepRegisterIconActive: '#DBC605',
  stepRegisterBorder: '#8F8F8F',
  borderInput: '#8F8F8F',
};

const formRegister: FormRegisterColors = {
  formRegisterInputIcon: '#8F8F8F',
  formRegisterInputText: '#8F8F8F',
};

const modalLogin: ModalLoginColors = {
  modalLoginBg: '#207D3F',
  inputLoginBg: '#346551',
  inputLoginTextColor: '#FFFFFF',
  inputLoginLabelColor: '#71AE95',
  iconCloseBg: '#346551',
  loginModalAppendIconColor: '#71AE95',

  primarylight: '#1C453D',
  primarydark: '#173831',

  chipBgPrimary: '#FF4A4A',
  chipBgSecondary: '#FF4A4A',
};

const weekDaysFilter: WeekDaysFilterColors = {
  weekdaysfilterBg: '#3E3E3E',
  weekdaysfilterTabActive: '#DBC605',
  weekdaysfilterTabTextActive: '#000000',
  weekdaysfilterTabTextInactive: '#5D5D5D',
  weekdaysfilterTabText: '#FFFFFF',

  textMenuSlider: '#FFFFFF',
};

const balanceTop: BalanceTopColors = {
  balanceTopBarBg: '#346551',
  balanceTopBarShadow: '#034D33',
  balanceTextColor: '#71AE95',
  balanceIconColor: '#71AE95',
};

const menuWalletUser: MenuWalletUserColors = {
  walletBgPrimary: '#5D5D5D',
  walletBgSecondary: '#5D5D5D',
  walletMenuBg: '#3E3E3E',
  walletMenuShadow: '#5D5D5D',
  walletDividerColor: '#2D2D2D',
  walletLogoutBg: '#5D5D5D',
  walletBalanceAvailableBg: '#4D4D4D',
  walletBalanceAvailableInner: '#3B3B3B',
  depositInputBg: '#3E3E3E',
  depositInputBgDesktop: '#5D5D5D',
  depositInputText: '#BFBFBF',
  depositInputBorder: '#BFBFBF',
  depositText: '#BFBFBF',
  depositDivider: '#8F8F8F',
  depositBtnGroupBg: '#3e3e3e',
  depositBtnGroupBgDesktop: '#8F8F8F',
  depositBtnGroupText: '#8F8F8F',
  depositBtnGroupTextDesktop: '#3e3e3e',
  withdrawText: '#BFBFBF',
  withdrawInputText: '#8F8F8F',
  withdrawInputBgModalSuccess: '#346551',
};

const modal: ModalColors = {
  modalBg: '#207D3F',
};

const betslip: BetslipColors = {
  betSlipBg: '#5D5D5D',
  BetslipBgDesktop: '#3E3E3E',
  betslipTabsText: '#8F8F8F',
  betslipMultipleBg: '#207D3F',
  betslipMultipleDesktopBg: '#207D3F',
  betslipMultipleBorder: '#DBC605',
  betslipMultipleShadow: '#5D5D5D',
  betslipMyBetsTabsText: '#8F8F8F',
  betslipMyBetsText: '#8F8F8F',
  betslipMyBetsStatusWon: '#43bc68',
  betslipMyBetsStatusLost: '#FF4A4A',
  betslipMyBetsStatusRefund: '#8F8F8F',
  betslipMyBetsTabOver: '#8F8F8F',
  betslipButtonInactive: '#8F8F8F',
  betslipButtonTextInactive: '#3E3E3E',
  betslipIconColor: '#DBC605',
  stakeFieldBorderColor: '#71AE95',
  stakeFieldTextColor: '#FFFFFF',
  stakeFieldPlaceholderColor: '#FFFFFF',
  betslipPriceBg: '#DBC605',
  betslipPriceTextColor: '#000000',
  betslipPriceShadow: '#207D3F',
  betslipSelectionText: '#8F8F8F',

  optInputBorder: '#8F8F8F',

  dialogSportAllTitle: '#DBC605',
  dialogSportAllDivider: '#DBC605',
  dialogSportAllBgOne: '#207D3F',
  dialogSportAllBgTwo: '#207D3F',

  myBetsDivider: '#DBC605',
  myBetsStatusSuccess: '#699719',
  myBetsModalFilterBg: '#207D3F',
  myBetsBtnGroupSelected: '#346551',
  myBetsBtnGroupSelectedText: '#71AE95',
  myBetsBtnGroupActive: '#207D3F',
};

const myBonus: MyBonusColors = {
  myBonusText: '#8F8F8F',
  myBonusBgCard: '#207D3F',
  myBonusBgCardOff: '#5D5D5D',
  myBonusScrollbarBg: '#346551',
  myBonusScrollbarColor: '#DBC605',
  myBonusAlertBg: '#346551',
};

const profileUser: ProfileUserColors = {
  userAvatarBg: '#2D2D2D',
  userAvatarText: '#DBC605',
  userProfileBg: '#2D2D2D',
  userProfileTabText: '#8F8F8F',
  userProfileText: '#8F8F8F',
  userProfileInputBg: '#2D2D2D',
};

const accordionCustom: AccordionCustomColors = {
  accordionBg: '#3E3E3E',
  accordionCaretIcon: '#8F8F8F',
  accordionDividerColor: '#3E3E3E',
};

const feedEvent: FeedEventColors = {
  feedEventOddBg: '#3E3E3E',
  feedEventEvenBg: '#5D5D5D',
  feedEventOdd: '#5D5D5D',
  feedEventEven: '#3E3E3E',
  'active-odds': '#DBC605',
  'active-odds-text': '#000000',
  marketsBg: '#5D5D5D',
};

const tables: TablesColors = {
  tableOddBg: '#3E3E3E',
  tableEvenBg: '#5D5D5D',
};

const modalCalendarFilter: ModalCalendarFilterColors = {
  datePickerHeaderBg: '#3E3E3E',
  datePickerHeaderTextColor: '#DBC605',
  datePickerBodyBg: '#2D2D2D',
  datePicketBtn: '#5D5D5D',
  dataPicektActionsBg: '#2D2D2D',

  selectionPriceTextColor: '#DBC605',
};

const modalSearchResult: ModalSearchResultColors = {
  modalSearchBackground: '#207D3F',
  modalSearchBorderColor: '#71AE95',
  inputSearchIconClose: '#71AE95',
  modalSearchBtnViewMoreColor: '#FFFFFF',
  modalSearchResultTitleBg: '#207D3F',

  listGamesTags: '#DBC605',
  listGamesTextTags: '#207D3F',
};

const snackbar = {
  snackBarHomeBGIcon: '#35A557',
  snackBarHomeIcon: '#19602F',
  snackBarHomeBg: '#43BC68',
  snackBarText: '#207D3F',
};

const alert = {
  alertBgSuccess: '#43BC68',
  alertTextSuccess: '#207D3F',
  alertIconSuccess: '#19602F',
  alertIconBgSuccess: '#35A557',
  alertBgError: '#FF4A4A',
  alertTextError: '#FFFFFF',
  alertIconError: '#A53838',
  alertIconBgError: '#6F2424',

  'alert-icon-pending': '#80848d',
  'alert-icon-bg-pending': '#676b74',
  'alert-icon-expired': '#3daed5',
  'alert-icon-bg-expired': '#2b7d9a',
  'alert-color-expired': '#10A0D3',
  'alert-icon-reimbursement': '#DCA141',
  'alert-icon-bg-reimbursement': '#AA7C32',
  'alert-color-reimbursement': '#D38810',
};

const casinoFavorite: CasinoFavoriteColors = {
  starColor: '#FFFFFF',
  starBackground: '#207D3F',
};

const footer: FooterColors = {
  footerBg: '#5D5D5D',
  footerBgO: '#4A4F5A',
  footerBgDesktop: '#207D3F',
  footerArrowTopBg: '#4A4F5A',
  footerArrowTopBgMobile: '#4A4F5A',
  footerIconSocialBg: '#dbc605',
  footerIconSocialBgTwo: '#af9f04',

  accumulatorBg: '#0D395E',
};

const lmt: LMTColors = {
  BgBb: '#0d1119',
  BgSrt: '#252930',
  HomeColor: '#045b72',
  BorderHome: '#045b72',
  ColorAway: '#6ca5b4',
  BorderAway: '#6ca5b4',
  SrtAwayBg: '#9EAFBE',
  SrtBaseAway: '#6bb2c3',
  SrtBaseHome: '#59a9bc',
  SrtBaseColor: '#59a9bc',
  SrtColorAway: '#b9edfa',
  SrtBaseBg: '#0b1e28',
  BgHoverable: '#0b1e28',
  BgBaseActive: '#0b232f',
  BgBaseHover: '#0b232f',
  SrBgBase: '#0d1119',
  BgSrBbBase: '#0c161f',
  SrBackgroundActive: '#0a2d3a',
  SrtPrimaryHover: '#1a88a2',
  SrtPrimary: '#1a88a2',
  SrtPrimaryDisabled: '#3395ad',
  SrtPrimaryBg: '#4da3b7',
  SrtPrimaryFour: '#055165',
  SrtPrimaryFive: '#055165',
  SrtPrimaryOne: '#055165',
  SrtPrimarySix: '#6bb2c3',
  PrimaryEightActive: '#1a88a2',
  SrtPrimaryNine: '#09313f',
  SrtPrimaryTen: '#03667f',
  SrtPrimaryEleven: '#1a88a2',
  SrtPrimaryThirteen: '#0c161f',
  SrtBaseTwo: '#4da3b7',
  SrtBaseNeutral: '#2c3037',
  SrtNeutralTwo: '#e7e7e8',
  SrtNeutralFour: '#aaacaf',
  SrtNeutralSeven: '#aaacaf',
  SrtNeutralFive: '#c3c4c6',
  SrtNeutralSix: '#474a50',
  SrtNeutralNine: '#1c1f27',
  SrtNeutralTen: '#2a2e35',
  SrtNeutralEleven: '#6e7075',
  SrtNeutralTwelve: '#9EAFBE',
  SrtAway: '#000000',
  SrtFillInfo: '#0072b1',
  SrtFillWarning: '#faa05a',
  SrtFillError: '#e43b3b',
  SrtFillBlue: '#2579ad',
  SrtFillGreen: '#2cbd00',
  SrtSoccerYellow: '#ffbf00',
  SrtSoccerRed: '#e43b3b',
  SrtSoccerSubst: '#4fbe30',
  SrtFillRlg: '#fdd835',
  SrtFillOrange: '#ffb848',
  SrtSoccerOrange: '#ef6c00',
  SrtRlgFour: '#e93a34',
  SrtRlgFive: '#941d1d',
  SrtPromoGreen: '#51d151',
  SrtPromoTwo: '#1b911b',
  SrtPromoFill: '#0e8094',
  SrtPromoFour: '#0a6cce',
  SrtPromoFive: '#4a9fe4',
  SrtTimeout: '#f5a623',
  SrtPenalty: '#f4a621',
  SrtNegative: '#ac182e',
  SrtFirstLine: '#4b90de',
  SrtMlbRun: '#009900',
  SrtMlbHit: '#f7ab11',
  BgSrtHit: '#00a4ff',
  SrtMlbError: '#d0021b',
};

export const themePix365Colors: ThemeColors = {
  ...primary,
  ...global,
  ...baseLayout,
  ...buttons,
  ...forms,
  ...snackbar,
  ...alert,
  ...footer,
  ...modal,
  ...toggleComponent,
  ...stepRegister,
  ...formRegister,
  ...modalLogin,
  ...weekDaysFilter,
  ...balanceTop,
  ...menuWalletUser,
  ...betslip,
  ...myBonus,
  ...profileUser,
  ...accordionCustom,
  ...feedEvent,
  ...tables,
  ...modalCalendarFilter,
  ...modalSearchResult,
  ...casinoFavorite,
  ...lmt,
};
