import { computed, ref } from 'vue';

import { addSeconds, formatDistance } from 'date-fns';
import { defineStore } from 'pinia';
import type { Logger } from 'tslog';
import { useRouter } from 'vue-router';

import type { User } from '@/@types/auth/auth.types';
import type { LoginRes } from '@/api/models/auth.model';
import FeedbackService from '@/services/Feedback.service';
import { storageServices } from '@/services/Storage.service';
import EventBusFactory from '@/utils/classes/EventBus';
import SingleHTTPFactory from '@/utils/classes/SingleHTTPFactory';
import { appLogger } from '@/utils/logger/LoggerConfig';

const AUTH_ROUTES = {
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  LOGOUT: '/auth/logout',
  CHANGE_PASSWORD: '/auth/change_password',
};

const TOKEN_DEFAULT_TIMEOUT_SECONDS = 2100;
const TOKEN_TIME_MARGIN_SECONDS = 300;
const TOKEN_TIME_TO_LOGOUT_MARGIN_SECONDS = 240;

const logger: Logger<any> = appLogger.getSubLogger({
  name: 'Auth Store',
});

export const useAuthStore = defineStore('authStore', () => {
  const singleHttp = SingleHTTPFactory.getSingleHTTP();
  const bus = EventBusFactory.getEventBus();
  const router = useRouter();

  /**
   * Main states section
   */
  const token = ref<string | null>(storageServices.getItem('token') || null);
  const tokenIssuedAt = ref<string | Date | null>(
    storageServices.getItem('tokenIssuedAt') || null,
  );
  const tokenExpiresAt = ref();

  // User information
  const user = ref<User | null>(null);

  /**
   * Calculated properties
   */
  const isAuthenticated = computed(() => {
    return !!token.value;
  });

  /**
   * Login Section
   */

  function resolveUserInfo(res: any) {
    if (!res) return;
    const loginData = res.data;

    token.value = loginData.token;
    tokenIssuedAt.value = loginData.token_issued_at;

    user.value = loginData.user;

    storageServices.setItem('token', loginData.token);
    storageServices.setItem(
      'tokenIssuedAt',
      loginData.token_issued_at as string,
    );

    setTokenCountdown(loginData.token_issued_at, loginData.token_expires_in);
  }

  // Login
  const [postLogin, { loading: loadingLogin }] = singleHttp.useAPI<LoginRes>({
    url: AUTH_ROUTES.LOGIN,
    method: 'post',
    options: {
      publicRequest: true,
      mockRequest: true,
    },
  });

  /**
   * @param email User's email
   * @param password User's password
   * @returns Promise<>
   */
  async function login(email: string, password: string) {
    return postLogin({
      data: { email, password },
      options: {
        onSuccessFeedbackMessage: `Seja bem-vindo!`,
        skipErrorUserFeedback: true,
        onSuccess: async (res) => {
          resolveUserInfo(res);

          await router.push('/');
        },
      },
    });
  }

  // Refresh token and useInfo
  const [postRefreshToken, { loading: loadingRefresh }] =
    singleHttp.useAPI<LoginRes>({
      url: AUTH_ROUTES.REFRESH,
      method: 'post',
      options: {
        mockRequest: true,
      },
    });

  // Loading state
  const loading = computed(() => {
    return loadingLogin || loadingRefresh;
  });

  /**
   * @description Executes silent user refresh
   */
  function silentRefresh() {
    postRefreshToken({
      options: {
        onSuccess: (res) => {
          resolveUserInfo(res);

          FeedbackService.onInfo('Temp: Silent refresh sucessfull');
          logger.info('Silent refresh sucessfull');
        },
      },
    });
  }

  const timeoutsID = ref<ReturnType<typeof setTimeout>[]>([]);

  // ! temp
  const intervalsID = ref<ReturnType<typeof setInterval>[]>([]);
  const tokenTimeToExpire = ref('');

  function clearTokenTimeouts() {
    for (let i = 0; i < timeoutsID.value.length; i++) {
      const timeoutID = timeoutsID.value[i];
      clearTimeout(timeoutID);
    }

    // ! temp
    clearInterval(intervalsID.value[0]);
    tokenTimeToExpire.value = '';
  }

  /**
   * @description Sets countdown for token refresh
   * @param issuedAt Date when the token was emitted
   * @param expiresIn Time in minutes from issuedAt when the token expires
   */
  function setTokenCountdown(issuedAt: string | Date, expiresIn?: number) {
    clearTokenTimeouts();

    // gets the date when the timeout
    tokenExpiresAt.value = addSeconds(
      issuedAt ? new Date(issuedAt) : new Date(),
      // expired in time minus 5 minutes
      (expiresIn || TOKEN_DEFAULT_TIMEOUT_SECONDS) - TOKEN_TIME_MARGIN_SECONDS,
    );

    const tokenExpiresIn = (Date.now() - tokenExpiresAt.value) * -1;

    timeoutsID.value[0] = setTimeout(() => {
      bus.emit('tokenAboutToExpire');

      // Request refresh token
      silentRefresh();
    }, tokenExpiresIn);

    timeoutsID.value[1] = setTimeout(
      async () => {
        bus.emit('tokenExpired');
        await logout();
      },
      tokenExpiresIn + TOKEN_TIME_TO_LOGOUT_MARGIN_SECONDS * 1000,
    );

    // ! Temp
    intervalsID.value[0] = setInterval(() => {
      tokenTimeToExpire.value = formatDistance(
        tokenExpiresAt.value,
        Date.now(),
      );
    }, 1000);
  }

  /**
   * Change password Section
   * ! TO BE IMPROVED AND IMPLEMENTED
   */

  const [putNewPassword, { loading: loadingNewPassword }] = singleHttp.useAPI({
    url: AUTH_ROUTES.CHANGE_PASSWORD,
    method: 'put',
  });

  /**
   * changePassword function
   * @param current_password
   * @param password
   * @param password_confirmation
   * @returns
   */
  async function changePassword(
    current_password: string,
    password: string,
    password_confirmation: string,
  ) {
    if (!token.value) return;
    await putNewPassword({
      data: {
        current_password,
        password,
        password_confirmation,
      },
      options: {
        onSuccessFeedbackMessage: `Sua senha foi alterada com sucesso`,
      },
    });
  }

  /**
   *  Logout section
   */
  const [postLogout] = singleHttp.useAPI({
    url: AUTH_ROUTES.LOGOUT,
    method: 'post',
    options: {
      mockRequest: true,
    },
  });

  /**
   * logout
   * @description Call http logout and empty all user state and tokens
   */
  async function logout() {
    try {
      await postLogout({
        options: {
          onSuccess: () => {
            FeedbackService.onWarn('Temp: Logout sucessfull');
          },
        },
      });
    } finally {
      // Empty all reactive states
      token.value = null;
      tokenExpiresAt.value = null;
      tokenIssuedAt.value = null;
      user.value = null;

      clearTokenTimeouts();

      // Clean storage
      storageServices.removeItems(['token', 'tokenIssuedAt']);

      // Redirect user to home page
      await router.push('/');
    }
  }

  return {
    // State
    user,
    token,
    tokenExpiresAt,
    loading,
    loadingNewPassword,
    // !temp
    tokenTimeToExpire,

    // Methods
    login,
    logout,
    changePassword,
    silentRefresh,

    // Security checks
    isAuthenticated,
  };
});
