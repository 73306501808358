<script setup lang="ts">
import { computed, ref } from 'vue';

import { useRouter } from 'vue-router';
import * as yup from 'yup';

import STextField from '@/components/shared/atoms/STextField/STextField.vue';
import SError from '@/components/shared/molecules/SError/SError.vue';
import { useAuthStore } from '@/stores/auth/auth.store';
import EventBusFactory from '@/utils/classes/EventBus';

const router = useRouter();
const bus = EventBusFactory.getEventBus();
const authStore = useAuthStore();

const validationSchema =  {
  username: yup.string().required('Esse campo é obrigatório'),
  password: yup.string().required('Esse campo é obrigatório')
};

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

const emits = defineEmits(['update:modelValue', 'openForgotPasswordModal']);

const showPassword = ref(false);

const formLogin = ref({
  username: '',
  password: ''
});

const errors = ref({
  username: '',
  password: '',
});

const disableLogin = computed(() => {
  let disabled = true;

  if(formLogin.value.username && formLogin.value.password && !(errors.value.username && errors.value.password)) {
    disabled = false;
  }

  return disabled;
});

const validateField = (fieldName: 'username' | 'password', value: any) => {
  validationSchema[fieldName].validate(value).then(() => {
    errors.value[`${fieldName}`] = '';
  })
  .catch((error: Error) => {
    errors.value[`${fieldName}`] = error.message;
    setTimeout(() => {
      errors.value[`${fieldName}`] = '';
    }, 3000);
  });
};

function forgotPassword() {
  bus.emit('forgotPassword');
  closeDialog();
}

function register() {
  router.push('/register');
  closeDialog();
}

function closeDialog() {
  formLogin.value.username = '';
  formLogin.value.password = '';
  emits('update:modelValue', false);
}

function login() {
  if (disableLogin.value) {
    return;
  }

  authStore.login(formLogin.value.username, formLogin.value.password).then(() => {
    closeDialog();
  });
}

function toggleShowPassword() {
  showPassword.value = !showPassword.value;

  const input = document.getElementById('inputPassword');

  if(input) {
    input.blur();
  }
}
</script>

<template>
  <v-dialog
    class="tenant-background-dialog"
    :modelValue="modelValue"
    @update:model-value="value => emits('update:modelValue', value)">
    <v-container class="d-flex justify-center px-0">
      <v-card class="pa-4 text-h5" max-width="380">
        <v-row no-gutters>
          <v-col cols="12">
            <p
              class="mt-1 welcome d-flex justify-center text-white font-size-16">
              bem-vindo à
            </p>
          </v-col>
          <v-col cols="12" class="d-flex position-relative justify-end">
            <v-icon
              class="mdi mdi-close-circle-outline"
              @click="closeDialog"></v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="py-4 d-flex justify-center">
            <!-- adicionar imagem por tenant -->
            <img src="@/assets/pixbet.svg?url" width="200" alt="Pixbet" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-4">
            <STextField
              @keyup.enter="login"
              @input="validateField('username', formLogin.username)"
              hide-details
              v-model="formLogin.username"
              placeholder="Nome de Usuário"
              :aria-details="errors.username"
              type="text" />
            <SError :error="errors.username" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <STextField
              @keyup.enter="login"
              hide-details
              id="inputPassword"
              @input="validateField('password', formLogin.password)"
              :type="showPassword ? 'text' : 'password'"
              :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="toggleShowPassword"
              v-model="formLogin.password"
              placeholder="Senha" />
            <SError :error="errors.password" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center pb-3">
            <v-btn
              :loading="authStore.loading.value"
              :disabled="disableLogin"
              @click="login"
              color="primary"
              variant="flat"
              class="font-weight-bold">
              entrar
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="py-1 text-center">
            <a
              @click="forgotPassword"
              class="font-weight-bold font-size-14 primary pointer">
              Esqueci minha senha.
            </a>
          </v-col>
          <v-col cols="12">
            <p class="text-center font-size-14 text-white">
              Não tem conta?
              <a @click="register" class="font-weight-bold primary pointer">
                Registre-se.
              </a>
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<style scoped lang="scss">
.welcome {
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  width: 92%;
}
</style>
