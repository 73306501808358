import { Locales } from '@/constants/Locale';
import { storageServices } from '@/services';

/**
 * Save locale in local storage.
 * @param value Locale to save.
 */
function saveLocale(value: string) {
  storageServices.setItem('locale', value);
}

/**
 * Get locale from local storage.
 * @returns Locale saved in local storage.
 */
function getLocale() {
  const locale = storageServices.getItem('locale');
  if (locale) return locale;
  return Locales.PT;
}

export default {
  saveLocale,
  getLocale,
};
