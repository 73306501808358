import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

const ENV_SEPARATOR = ';';

export const useEnvironmentStore = defineStore('environmentStore', () => {
  const use_LOCAL_API_URL: boolean =
    import.meta.env.SINGLESOFTWARE_API_DEVELOPMENT_MODE === 'true';

  const env_LOCAL_API_URL: string = import.meta.env
    .SINGLESOFTWARE_API_DEVELOPMENT_URL;
  const env_API_URL: string = import.meta.env.SINGLESOFTWARE_API_URL;
  const env_ASSETS_ROOT: string = import.meta.env.SINGLESOFTWARE_ASSETS_ROOT;
  const env_BRAND: string = import.meta.env.SINGLESOFTWARE_BRAND;

  const list_API_URL = env_API_URL.split(ENV_SEPARATOR);
  const list_ASSETS_ROOT = env_ASSETS_ROOT.split(ENV_SEPARATOR);
  const list_BRAND = env_BRAND.split(ENV_SEPARATOR);

  const selectedEnvIndex = ref(0);

  const selected_API_URL = computed(() => {
    return use_LOCAL_API_URL
      ? env_LOCAL_API_URL
      : list_API_URL[selectedEnvIndex.value] || list_API_URL[0];
  });
  const selected_ASSETS_ROOT = computed(() => {
    return list_ASSETS_ROOT[selectedEnvIndex.value] || list_ASSETS_ROOT[0];
  });
  const selected_BRAND = computed(() => {
    return list_BRAND[selectedEnvIndex.value];
  });

  const brandLength = list_BRAND.length;

  const showDevBadge: boolean =
    import.meta.env.SINGLESOFTWARE_SHOW_BADGE === 'true';

  return {
    selectedEnvIndex,
    brandLength,
    // COMPUTED ITEMS
    selected_API_URL,
    selected_ASSETS_ROOT,
    selected_BRAND,

    //LIST OF OPTIONS
    list_API_URL,
    list_ASSETS_ROOT,
    list_BRAND,

    showDevBadge,
  };
});
