<template>
      <div class="modal-wrapper">
          <v-row>
              <v-col cols="12">
                  <v-icon class="key-icon" color="success" icon="mdi mdi-key" />
                  <div v-if="errors && errors[0]">
                    <h1  class="ml-2 mr-1">{{ 'Opa!' }}</h1>
                    <h2 class="mx-4">{{ errors[0] }}</h2>
                  </div>

                  <div v-else>
                    <h1>{{ 'Pedir nova senha' }} </h1>
                  </div>
              </v-col>
          </v-row>

          <v-btn
            class="close-icon"
            icon="mdi mdi-close"
            variant="flat"
            @click="emits('close')" />

          <v-form ref="form" @submit.prevent="submit">
              <div class="login-form-wrapper">
                  <v-row no-gutters v-if="messageIsSent">
                      <v-col cols="12">
                          <v-alert variant="tonal" color="success" class="alert">
                              {{ 'Enviamos um e-mail para você redefinir a senha!' }}
                          </v-alert>
                      </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-4">
                      <v-col cols="12">
                          <v-text-field
                            type="email"
                            placeholder="E-mail"
                            v-model="email"
                            bg-color="input"
                            density="compact"
                            variant="solo"
                            :rules="emailRules"
                            required>
                            <template v-slot:message="{ message }">
                              <SError :error="message" class="mb-4 float-left" />
                            </template>
                          </v-text-field>
                      </v-col>
                  </v-row>

                  <v-row no-gutters>
                      <v-col cols="12">
                          <v-btn
                              class="send-button"
                              elevation="2"
                              @click="submit"
                              :loading="isSendingAPIRequest">
                              {{ 'Enviar' }}
                          </v-btn>
                      </v-col>
                  </v-row>

                  <v-row no-gutters class="my-4">
                      <v-col cols="12">
                          <p>
                              {{ 'Não tem conta?' }}
                              <a class="ml-1 font-weight-bold" @click="goToRegister">
                                {{ 'Registre-se' }}
                              </a>
                          </p>
                      </v-col>
                  </v-row>
              </div>
          </v-form>
      </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useRouter } from 'vue-router';

import SError from '@/components/shared/molecules/SError/SError.vue';
import emailRules from '@/views/public/auth/rules/email';

const emits = defineEmits(['close']);
const router = useRouter();

const email = ref('');
const errors = ref([]);
const messageIsSent = ref(false);
const isSendingAPIRequest = ref(false);

async function submit() {
  if (isSendingAPIRequest.value) {
    return;
  }

  isSendingAPIRequest.value = true;
  await sendRequest();
  isSendingAPIRequest.value = false;

  openSentMessegeAlert();
}

async function sendRequest() {
  try {
    return new Promise((resolve) => {
      setTimeout(() => resolve({}), 1000);
    });
  } catch (error: any) {
    if (error.response && error.response.data) {
      errors.value = error.response.data.errors;
    }
  }
}

function openSentMessegeAlert() {
  messageIsSent.value = true;
  setTimeout(closeSentMessegeAlert, 3000);
}

function closeSentMessegeAlert() {
  messageIsSent.value = false;
}

function goToRegister() {
  emits('close');
  router.push('/register');
}

</script>

<style scoped lang="scss">
a:hover { cursor: pointer }

.modal {
  max-width: 450px;
  width: 90%;
}

.modal-wrapper {
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-neutral));
  position: relative;
  padding: 6px 12px;
  text-align: center;
  border-radius: 5px;
}

.modal-wrapper h1 {
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(var(--v-theme-neutral));
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px 0 25px 0;
}

.modal-wrapper h2 {
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 13px;
  font-weight: 300;
  letter-spacing: 1.2px;
}

.modal-wrapper img {
  max-width: 200px;
  margin-bottom: 10px;
}

.close-icon {
  background-color: #ffffff29;
  color: rgb(var(--v-theme-neutral));
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  width: 25px;
  height: 25px;
}

.login-form-wrapper {
  text-align: center;
  color: rgb(var(--v-theme-neutral));
  text-decoration: none;
}

.login-form-wrapper p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.65px;
  text-align: center;
}

.send-button {
  width: 158px;
  height: 43px;
  background-color: rgb(var(--v-theme-primary));
  font-size: 16px;
  font-weight: bold;
  color: rgb(var(--v-theme-background));
  letter-spacing: 0.8px;
}

.key-icon {
  font-size: 46px;
  margin-top: 15px;
}

.alert {
  border: 1px solid rgb(var(--v-theme-success));
  font-size: 15px;
}
</style>
