import { createApp } from 'vue';

import { registerPlugins } from '@/plugins';

import './api/mocks'; // Mock Api data
import '@/styles/main.scss';
import App from './App.vue';
import FeedbackService from './services/Feedback.service';

const app = createApp(App);

registerPlugins(app);

/* Global Error Handler */
app.config.errorHandler = (_err, _instance, info) => {
  FeedbackService.onError(new Error(info));
};

app.mount('#app');
