export type StorageKeys = keyof typeof StorageService.STORAGE_KEYS;

export interface StorageAction {
  getItem(key: StorageKeys): string | null;
  setItem(key: StorageKeys, value: string): void;
  removeItem(key: StorageKeys): string | null;
  removeItems(keys: StorageKeys[]): void;
}

class StorageService implements StorageAction {
  static keysPrefix = 'singlebet'; // Shared prefix for all keys
  static STORAGE_KEYS = {
    token: StorageService.getStorageKeyName('token'),
    tokenIssuedAt: StorageService.getStorageKeyName('tokenIssuedAt'),
    locale: StorageService.getStorageKeyName('locale'),
  };

  private storage = sessionStorage;

  static getStorageKeyName(key: string) {
    return `${StorageService.keysPrefix}-${key}`;
  }

  /**
   * Retrieves a value from the storage.
   * @param {string} key - The key associated with the value.
   * @returns {string | null} The stored value or null if not found.
   */
  getItem(key: StorageKeys): string | null {
    return this.storage.getItem(StorageService.getStorageKeyName(key));
  }

  /**
   * Stores a value in the storage.
   * @param {string} key - The key to store the value under.
   * @param {string} value - The value to be stored.
   */
  setItem(key: StorageKeys, value: any): void {
    this.storage.setItem(StorageService.getStorageKeyName(key), value);
  }

  /**
   * Removes a value from the storage. Also returns the removed value.
   * @param {string} key - The key associated with the value.
   * @returns {string | null} storedValue - The stored value or null if not found.
   */
  removeItem(key: StorageKeys): string | null {
    const storedValue = this.getItem(key);
    this.storage.removeItem(StorageService.getStorageKeyName(key));
    return storedValue;
  }

  /**
   * Removes multiple values from the storage.
   * @param {string[]} keys - The keys associated with the values.
   * @returns {void}
   */
  removeItems(keys: StorageKeys[]): void {
    keys.forEach((key) => this.removeItem(key));
  }
}

// Create an instance of the storage service
export const storageServices: StorageAction = new StorageService();

export default storageServices;
