import type { RouteRecordRaw } from 'vue-router';

import { AboutRoute } from '@/router/routes/public/about';

const PublicRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Homepage',
    component: () => import('@/pages/index.vue'),
  },
  AboutRoute,
  {
  path: '/passwordreset',
  name: 'PasswordReset',
  component: () =>
    import('@/views/public/auth/passwordReset/passwordReset.vue'),
  redirect: '/',
  children: [
    {
      path: '/passwordreset',
      name: 'PasswordReset',
      component: () =>
        import('@/views/public/auth/passwordReset/passwordReset.vue'),
    },
  ],
}
];

export { PublicRoutes };
