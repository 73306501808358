import type { RouteRecordRaw } from 'vue-router';

import { BetHistoryRoute } from '@/router/routes/private/betHistory';
import { DepositRoute } from '@/router/routes/private/deposit';
import { LimitsRouter } from '@/router/routes/private/LimitsManagament';
import { UserRoute } from '@/router/routes/private/user';
import { WalletRouter } from '@/router/routes/private/Wallet';
import { WithdrawRoute } from '@/router/routes/private/withdraw';

const PrivateRoutes: RouteRecordRaw[] = [
  BetHistoryRoute,
  DepositRoute,
  WalletRouter,
  WithdrawRoute,
  UserRoute,
  LimitsRouter,
];

export { PrivateRoutes };
