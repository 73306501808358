/**
 * @file plugins/vuetify.ts
 * Framework documentation: https://vuetifyjs.com`
 */

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as labsComponents from 'vuetify/labs/components';

import { Theme1xpix, ThemePix365, ThemePixbet, ThemePixcasino } from '@/themes';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,

  // https://vuetifyjs.com/en/features/icon-fonts/
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },

  // https://next.vuetifyjs.com/features/theme/
  theme: {
    defaultTheme: 'Pixbet',
    themes: {
      Pixbet: ThemePixbet,
      '1xpix': Theme1xpix,
      Pix365: ThemePix365,
      Pixcasino: ThemePixcasino,
      Demo: {
        dark: true,
        colors: {
          primary: '#265C51',
          secondary: '#0E604F',
          tertiary: '#31786A',
          quaternary: '#173831',
          quintenary: '#307567',
          active: '#215248',
          inactive: '#D6D6D6',
          inactiveText: '#3B8F7E',
          textOpacity: '#46AB97',
          success: '#E2DD2E',
          error: '#FF5252',
          // info: '',
          // warning: '',
          neutral: '#FFFFFF',

          primarylight: '#1C453D',
          primarydark: '#173831',
        },
      },
      Default: {
        dark: true,
        colors: {
          primary: '#6D7D85',
          secondary: '#566369',
          tertiary: '#30373B',
          quaternary: '#2E3538',
          quintenary: '#6F8087',
          active: '#5C6A70',
          inactive: '#D6D6D6',
          inactiveText: '#8E8E8E',
          textOpacity: '#9EAFBE',
          success: '#A6E732',
          error: '#FF5252',
          // info: '',
          // warning: '',
          neutral: '#FFFFFF',

          primarylight: '#434D52',
          primarydark: '#363F42',
        },
      },
    },
  },
});
