/**
 * @file @/themes/index.ts
 *
 * @description Theme configurations
 */

import type { ThemeConfigProps } from '@/@types/theme';

const themeConfig: ThemeConfigProps = {
  actTheme: 'pixbet',
  fontTheme: 'Poppins',
};

export default themeConfig;
