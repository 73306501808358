import { ThemeColors } from '@/@types/theme';
import {
  AccordionCustomColors,
  AlertColors,
  BalanceTopColors,
  BaseLayoutColors,
  BetslipColors,
  ButtonsColors,
  CasinoFavoriteColors,
  FeedEventColors,
  FooterColors,
  FormRegisterColors,
  FormsColors,
  GlobalColors,
  LMTColors,
  MenuWalletUserColors,
  ModalCalendarFilterColors,
  ModalColors,
  ModalLoginColors,
  ModalSearchResultColors,
  MyBonusColors,
  PrimaryColors,
  ProfileUserColors,
  SnackbarColors,
  StepRegisterColors,
  TablesColors,
  ToggleComponentColors,
  WeekDaysFilterColors,
} from '@/@types/theme/colors.types';

const primary: PrimaryColors = {
  background: '#0C395E',
  surface: '#092C44',
  primaryDark: '#0D1119',
  primary: '#0D1119',
  secondary: '#1C2331',
  tertiary: '#0D1119',
  quaternary: '#0D1119',
  quintenary: '#0D1119',
  active: '#007B98',
  inactive: '#6A6D7B',
  inactiveText: '#8A8D9D',
  textOpacity: '#9EAFBE',
  textSecondary: '#F5F5F5',
  textTertiary: '#FAFAFA',
  textInputRegister: '#9EAFBE',
  success: '#007B98',
  error: '#BC4343',
  info: '#2DA5FF',
  warning: '#ff8800',
  neutral: '#FFFFFF',
  bgHeaderAccordionEvents: '#0E1A27',
  shadowHeaderAccordionEvents: '#0D1119',
  chevronAccordionColor: '#363B51',
  scrollbarColor: '#061D2C',
  scrollbarColorDesktop: '#061D2C',
  messageSuccess: '#699719',
};

const global: GlobalColors = {
  bodyBg: '#1C2331',
  bodyText: '#FFFFFF',
};

const baseLayout: BaseLayoutColors = {
  topBarBg: '#0D1119',
  menuMobileBg: '#1C2331',
  bottomNavigationBg: '#0D1119',
  bottomNavigationShadow: '#000000',
};

const buttons: ButtonsColors = {
  buttonBg: '#007B98',
  buttonLoginBg: '#1C2331',
  buttonTextColor: '#0D1119',
  buttonTextColorPromotion: '#346551',
  buttonBgInactive: '#6A6D7B',
  buttonTextColorInactive: '#8A8D9D',
  buttonWalletLogoutBg: '#1C2331',
  buttonWalletLogoutShadow: '#0D1119',
};

const forms: FormsColors = {
  inputBg: '#0D1119',
  inputSearchBg: '#0D1119',
  inputTextColor: '#FFFFFF',
  inputIconColor: '#6A6D7B',
  pink: '#9A1FBF',
  goldAlet: '#E5AC00',
  lightsuccess: '#9EAFBE',
};

const toggleComponent: ToggleComponentColors = {
  toggleChatBg: '#000000',
  toggleChatBgInner: '#0D395E',
  toggleChatIcon: '#43BC68',
  toggleBonusBg: '#1C2331',
  toggleBonusBgInner: '#0D395E',
  toggleBonusBgDesktop: '#0D1119',
  toggleBonusBgDesktopInner: '#0D1119',
  toggleBonusTextColor: '#FFFFFF',
  toggleBonusIcon: '#43BC68',
};

const stepRegister: StepRegisterColors = {
  stepRegisterActive: '#007B98',
  stepRegisterInactive: '#0D1119',
  borderInput: '#6A6D7B',
  stepRegisterTextActive: '#0D1119',
  stepRegisterTextInactive: '#FFFFFF',
  stepRegisterIconActive: '#0D1119',
  stepRegisterBorder: '#9EAFBE',
};

const formRegister: FormRegisterColors = {
  formRegisterInputIcon: '#8F8F8F',
  formRegisterInputText: '#8F8F8F',
};

const modalLogin: ModalLoginColors = {
  modalLoginBg: '#1C2331',
  inputLoginBg: '#0D1119',
  inputLoginTextColor: '#FFFFFF',
  inputLoginLabelColor: '#6A6D7B',
  iconCloseBg: '#6A6D7B',
  loginModalAppendIconColor: '#6A6D7B',
  primarylight: '#1C453D',
  primarydark: '#173831',
  chipBgPrimary: '#BC4343',
  chipBgSecondary: '#BC4343',
};

const weekDaysFilter: WeekDaysFilterColors = {
  weekdaysfilterBg: '#0D1119',
  weekdaysfilterTabActive: '#007B98',
  weekdaysfilterTabTextActive: '#0D1119',
  weekdaysfilterTabText: '#FFFFFF',
  weekdaysfilterTabTextInactive: '#8A8D9D',
  textMenuSlider: '#FFFFFF',
};

const balanceTop: BalanceTopColors = {
  balanceTopBarBg: '#1C2331',
  balanceTopBarShadow: '#1C2331',
  balanceTextColor: '#FFFFFF',
  balanceIconColor: '#FFFFFF',
};

const menuWalletUser: MenuWalletUserColors = {
  walletBgPrimary: '#1C2331',
  walletBgSecondary: '#1C2331',
  walletMenuBg: '#0D1119',
  walletMenuShadow: '#000000',
  walletDividerColor: '#6A6D7B',
  walletLogoutBg: '#5D5D5D',
  walletBalanceAvailableBg: '#0D1119',
  walletBalanceAvailableInner: '#0D1119',
  depositInputBg: '#0D1119',
  depositInputBgDesktop: '#1C2331',
  depositInputText: '#FFFFFF',
  depositInputBorder: '#FFFFFF',
  depositText: '#6A6D7B',
  depositDivider: '#6A6D7B',
  depositBtnGroupBgDesktop: '#1C2331',
  depositBtnGroupBg: '#007B98',
  depositBtnGroupText: '#000000',
  depositBtnGroupTextDesktop: '#000000',
  withdrawText: '#6A6D7B',
  withdrawInputText: '#6A6D7B',
  withdrawInputBgModalSuccess: '#0D1119',
};

const modal: ModalColors = {
  modalBg: '#1C2331',
};

const betslip: BetslipColors = {
  betSlipBg: '#1C2331',
  BetslipBgDesktop: '#0D1119',
  betslipTabsText: '#6A6D7B',
  betslipMultipleBg: '#0D1119',
  betslipMultipleDesktopBg: '#1C2331',
  betslipMultipleBorder: '#007B98',
  betslipMultipleShadow: '#000000',
  betslipMyBetsTabsText: '#6A6D7B',
  betslipMyBetsText: '#6A6D7B',
  betslipMyBetsStatusWon: '#43bc68',
  betslipMyBetsStatusLost: '#BC4343',
  betslipMyBetsStatusRefund: '#6A6D7B',
  betslipMyBetsTabOver: '#6A6D7B',
  betslipButtonInactive: '#6A6D7B',
  betslipButtonTextInactive: '#8A8D9D',
  betslipIconColor: '#9EAFBE',
  stakeFieldBorderColor: '#6A6D7B',
  stakeFieldTextColor: '#FFFFFF',
  stakeFieldPlaceholderColor: '#9EAFBE',
  betslipPriceBg: '#007B98',
  betslipPriceTextColor: '#1C2331',
  betslipPriceShadow: '#0D1119',
  betslipSelectionText: '#8F8F8F',

  optInputBorder: '#6A6D7B',

  dialogSportAllTitle: '#007B98',
  dialogSportAllDivider: '#454854',
  dialogSportAllBgOne: '#454854',
  dialogSportAllBgTwo: '#0D1119',

  myBetsDivider: '#6A6D7B',
  myBetsStatusSuccess: '#699719',
  myBetsModalFilterBg: '#1C2331',
  myBetsBtnGroupSelected: '#0D1119',
  myBetsBtnGroupSelectedText: '#6A6D7B',
  myBetsBtnGroupActive: '#1C2331',
};

const myBonus: MyBonusColors = {
  myBonusText: '#8F8F8F',
  myBonusBgCard: '#1C2331',
  myBonusBgCardOff: '#383b44',
  myBonusScrollbarBg: '#1C2331',
  myBonusScrollbarColor: '#007B98',
  myBonusAlertBg: '#1C2331',
};

const profileUser: ProfileUserColors = {
  userAvatarBg: '#0D1119',
  userAvatarText: '#007B98',
  userProfileBg: '#0D1119',
  userProfileTabText: '#6A6D7B',
  userProfileText: '#6A6D7B',
  userProfileInputBg: '#1C2331',
};

const accordionCustom: AccordionCustomColors = {
  accordionBg: '#0D1119',
  accordionCaretIcon: '#6A6D7B',
  accordionDividerColor: '#6A6D7B',
};

const feedEvent: FeedEventColors = {
  feedEventOddBg: '#0D1119',
  feedEventEvenBg: '#1C2331',
  feedEventOdd: '#1C2331',
  feedEventEven: '#0D1119',
  'active-odds': '#0C7B98',
  'active-odds-text': '#0D1119',
  marketsBg: '#1C2331',
};

const tables: TablesColors = {
  tableOddBg: '#0d1119',
  tableEvenBg: '#1C2331',
};

const modalCalendarFilter: ModalCalendarFilterColors = {
  datePickerHeaderBg: '#007B98',
  datePickerHeaderTextColor: '#0D1119',
  datePickerBodyBg: '#1C2331',
  datePicketBtn: '#0D1119',
  dataPicektActionsBg: '#1C2331',

  selectionPriceTextColor: '#FFFFFF',
};

const modalSearchResult: ModalSearchResultColors = {
  modalSearchBackground: '#1C2331',
  modalSearchBorderColor: '#6A6D7B',
  inputSearchIconClose: '#6A6D7B',
  modalSearchBtnViewMoreColor: '#8A8D9D',
  modalSearchResultTitleBg: '#1C2331',

  listGamesTags: '#007b98',
  listGamesTextTags: '#0D1119',
};

const snackbar: SnackbarColors = {
  snackBarHomeBGIcon: '#35A557',
  snackBarHomeIcon: '#19602F',
  snackBarHomeBg: '#43BC68',
  snackBarText: '#0D1119',
};

const alert: AlertColors = {
  alertBgSuccess: '#43BC68',
  alertTextSuccess: '#0D1119',
  alertIconSuccess: '#19602F',
  alertIconBgSuccess: '#35A557',
  alertBgError: '#BC4343',
  alertTextError: '#FFFFFF',
  alertIconError: '#A53838',
  alertIconBgError: '#6F2424',

  'alert-icon-pending': '#80848d',
  'alert-icon-bg-pending': '#676b74',
  'alert-icon-expired': '#3daed5',
  'alert-icon-bg-expired': '#2b7d9a',
  'alert-color-expired': '#10A0D3',
  'alert-icon-reimbursement': '#DCA141',
  'alert-icon-bg-reimbursement': '#AA7C32',
  'alert-color-reimbursement': '#D38810',
};

const casinoFavorite: CasinoFavoriteColors = {
  starColor: '#FFFFFF',
  starBackground: '#007B98',
};

const footer: FooterColors = {
  footerBg: '#1C2331',
  footerBgO: '#4A4F5A',
  footerBgDesktop: '#0D1119',
  footerArrowTopBg: '#4A4F5A',
  footerArrowTopBgMobile: '#4A4F5A',
  footerIconSocialBg: '#007b98',
  footerIconSocialBgTwo: '#045b72',

  accumulatorBg: '#0D395E',
};

const lmt: LMTColors = {
  BgBb: '#0d1119',
  BgSrt: '#252930',
  HomeColor: '#045b72',
  BorderHome: '#045b72',
  ColorAway: '#6ca5b4',
  BorderAway: '#6ca5b4',
  SrtAwayBg: '#9EAFBE',
  SrtBaseAway: '#6bb2c3',
  SrtBaseHome: '#59a9bc',
  SrtBaseColor: '#59a9bc',
  SrtColorAway: '#b9edfa',
  SrtBaseBg: '#0b1e28',
  BgHoverable: '#0b1e28',
  BgBaseActive: '#0b232f',
  BgBaseHover: '#0b232f',
  SrBgBase: '#0d1119',
  BgSrBbBase: '#0c161f',
  SrBackgroundActive: '#0a2d3a',
  SrtPrimaryHover: '#1a88a2',
  SrtPrimary: '#1a88a2',
  SrtPrimaryDisabled: '#3395ad',
  SrtPrimaryBg: '#4da3b7',
  SrtPrimaryFour: '#055165',
  SrtPrimaryFive: '#055165',
  SrtPrimaryOne: '#055165',
  SrtPrimarySix: '#6bb2c3',
  PrimaryEightActive: '#1a88a2',
  SrtPrimaryNine: '#09313f',
  SrtPrimaryTen: '#03667f',
  SrtPrimaryEleven: '#1a88a2',
  SrtPrimaryThirteen: '#0c161f',
  SrtBaseTwo: '#4da3b7',
  SrtBaseNeutral: '#2c3037',
  SrtNeutralTwo: '#e7e7e8',
  SrtNeutralFour: '#aaacaf',
  SrtNeutralSeven: '#aaacaf',
  SrtNeutralFive: '#c3c4c6',
  SrtNeutralSix: '#474a50',
  SrtNeutralNine: '#1c1f27',
  SrtNeutralTen: '#2a2e35',
  SrtNeutralEleven: '#6e7075',
  SrtNeutralTwelve: '#9EAFBE',
  SrtAway: '#000000',
  SrtFillInfo: '#0072b1',
  SrtFillWarning: '#faa05a',
  SrtFillError: '#e43b3b',
  SrtFillBlue: '#2579ad',
  SrtFillGreen: '#2cbd00',
  SrtSoccerYellow: '#ffbf00',
  SrtSoccerRed: '#e43b3b',
  SrtSoccerSubst: '#4fbe30',
  SrtFillRlg: '#fdd835',
  SrtFillOrange: '#ffb848',
  SrtSoccerOrange: '#ef6c00',
  SrtRlgFour: '#e93a34',
  SrtRlgFive: '#941d1d',
  SrtPromoGreen: '#51d151',
  SrtPromoTwo: '#1b911b',
  SrtPromoFill: '#0e8094',
  SrtPromoFour: '#0a6cce',
  SrtPromoFive: '#4a9fe4',
  SrtTimeout: '#f5a623',
  SrtPenalty: '#f4a621',
  SrtNegative: '#ac182e',
  SrtFirstLine: '#4b90de',
  SrtMlbRun: '#009900',
  SrtMlbHit: '#f7ab11',
  BgSrtHit: '#00a4ff',
  SrtMlbError: '#d0021b',
};

export const theme1xpixColors: ThemeColors = {
  ...primary,
  ...global,
  ...baseLayout,
  ...buttons,
  ...forms,
  ...snackbar,
  ...alert,
  ...footer,
  ...modal,
  ...toggleComponent,
  ...stepRegister,
  ...formRegister,
  ...modalLogin,
  ...weekDaysFilter,
  ...balanceTop,
  ...menuWalletUser,
  ...betslip,
  ...myBonus,
  ...profileUser,
  ...accordionCustom,
  ...feedEvent,
  ...tables,
  ...modalCalendarFilter,
  ...modalSearchResult,
  ...casinoFavorite,
  ...lmt,
};
