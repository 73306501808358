import type { ThemeColors } from '@/@types/theme';
import type {
  AccordionCustomColors,
  AlertColors,
  BalanceTopColors,
  BaseLayoutColors,
  BetslipColors,
  ButtonsColors,
  CasinoFavoriteColors,
  FeedEventColors,
  FooterColors,
  FormRegisterColors,
  FormsColors,
  GlobalColors,
  LMTColors,
  MenuWalletUserColors,
  ModalCalendarFilterColors,
  ModalColors,
  ModalLoginColors,
  ModalSearchResultColors,
  MyBonusColors,
  PrimaryColors,
  ProfileUserColors,
  SnackbarColors,
  StepRegisterColors,
  TablesColors,
  ToggleComponentColors,
  WeekDaysFilterColors,
} from '@/@types/theme/colors.types';

const primary: PrimaryColors = {
  background: '#0C395E',
  surface: '#092C44',
  error: '#B00020',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
  neutral: '#FFF',

  primary: '#A6E732',
  primaryDark: '#416e27',
  secondary: '#005D9F',
  tertiary: '#31786A',
  quaternary: '#173831',
  quintenary: '#307567',
  active: '#215248',
  inactive: '#D6D6D6',
  inactiveText: '#3B8F7E',
  textOpacity: '#46AB97',
  textSecondary: '#F5F5F5',
  textTertiary: '#FAFAFA',
  textInputRegister: '#9EAFBE',
  scrollbarColor: '#061D2C',
  scrollbarColorDesktop: '#061D2C',
  bgHeaderAccordionEvents: '#144F85',
  shadowHeaderAccordionEvents: '#0D1119',
  chevronAccordionColor: '#9EAFBE',
  messageSuccess: '#A6E732',
};

const global: GlobalColors = {
  bodyBg: '#0D395E',
  bodyText: '#FFFFFF',
};

const baseLayout: BaseLayoutColors = {
  topBarBg: '#035C9F',
  menuMobileBg: '#0D395E',
  bottomNavigationBg: '#035C9F',
  bottomNavigationShadow: '#0D395E',
};

const buttons: ButtonsColors = {
  buttonBg: '#A6E732',
  buttonLoginBg: '#0D395E',
  buttonTextColor: '#0D395E',
  buttonBgInactive: '#D6D6D6',
  buttonTextColorInactive: '#8E8E8E',
  buttonTextColorPromotion: '#0D395E',

  buttonWalletLogoutBg: '#1771B6',
  buttonWalletLogoutShadow: '#092C44',
};

const forms: FormsColors = {
  inputBg: '#13507E',
  inputSearchBg: '#0D1119',
  inputTextColor: '#FFFFFF',
  inputIconColor: '#6A6D7B',
  pink: '#A75CFF',
  goldAlet: '#E5AC00',
  lightsuccess: '#9EAFBE',
};

const toggleComponent: ToggleComponentColors = {
  toggleChatBg: '#2D71A4',
  toggleChatBgInner: '#0D395E',
  toggleChatIcon: '#A6E732',
  toggleBonusBg: '#092C44',
  toggleBonusBgInner: '#0D395E',
  toggleBonusBgDesktop: '#092C44',
  toggleBonusBgDesktopInner: '#0D395E',
  toggleBonusTextColor: '#FFFFFF',
  toggleBonusIcon: '#43BC68',
};

const stepRegister: StepRegisterColors = {
  stepRegisterActive: '#A6E732',
  stepRegisterInactive: '#13507E',
  borderInput: '#9EAFBE',
  stepRegisterTextActive: '#0D1119',
  stepRegisterTextInactive: '#FFFFFF',
  stepRegisterIconActive: '#0D1119',
  stepRegisterBorder: '#9EAFBE',
};

const formRegister: FormRegisterColors = {
  formRegisterInputIcon: '#8F8F8F',
  formRegisterInputText: '#8F8F8F',
};

const modalLogin: ModalLoginColors = {
  modalLoginBg: '#0D395E',
  inputLoginBg: '#13507E',
  inputLoginTextColor: '#FFFFFF',
  inputLoginLabelColor: '#9EAFBE',
  iconCloseBg: '#55748E',
  loginModalAppendIconColor: '#2DA5FF',
  primarylight: '#144F85',
  primarydark: '#092C44',
  chipBgPrimary: '#A6E732',
  chipBgSecondary: '#00802F',
};

const weekDaysFilter: WeekDaysFilterColors = {
  weekdaysfilterBg: '#092C44',
  weekdaysfilterTabActive: '#074B7E',
  weekdaysfilterTabTextActive: '#A6E732',
  weekdaysfilterTabText: '#2D71A4',
  weekdaysfilterTabTextInactive: '#8A8D9D',
  textMenuSlider: '#FFFFFF',
};

const balanceTop: BalanceTopColors = {
  balanceTopBarBg: '#074B7E',
  balanceTopBarShadow: '#092C44',
  balanceTextColor: '#D6D6D6',
  balanceIconColor: '#D6D6D6',
};

const menuWalletUser: MenuWalletUserColors = {
  walletBgPrimary: '#074b7e',
  walletBgSecondary: '#092C44',
  walletMenuBg: '#074B7E',
  walletMenuShadow: '#000000',
  walletDividerColor: '#6A6D7B',
  walletLogoutBg: '#5D5D5D',
  walletBalanceAvailableBg: '#0F5181',
  walletBalanceAvailableInner: '#0F5181',
  depositInputBg: '#13507E',
  depositInputBgDesktop: '#035C9F',
  depositInputText: '#BFBFBF',
  depositInputBorder: '#BFBFBF',
  depositText: '#6A6D7B',
  depositDivider: '#2DA5FF',
  depositBtnGroupBg: '#035C9F',
  depositBtnGroupBgDesktop: '#035C9F',
  depositBtnGroupText: '#FFFFFF',
  depositBtnGroupTextDesktop: '#FFFFFF',
  withdrawText: '#BFBFBF',
  withdrawInputText: '#9EAFBE',
  withdrawInputBgModalSuccess: '#074B7E',
};

const modal: ModalColors = {
  modalBg: '#0D395E',
};

const betslip: BetslipColors = {
  betSlipBg: '#0D395E',
  BetslipBgDesktop: '#092C44',
  betslipTabsText: '#FFFFFF',
  betslipMultipleBg: '#074B7E',
  betslipMultipleDesktopBg: '#074B7E',
  betslipMultipleBorder: '#074B7E',
  betslipMultipleShadow: '#000000',
  betslipMyBetsTabsText: '#FFFFFF',
  betslipMyBetsText: '#9EAFBE',
  betslipMyBetsStatusWon: '#A6E732',
  betslipMyBetsStatusLost: '#E74040',
  betslipMyBetsStatusRefund: '#8E8E8E',
  betslipMyBetsTabOver: '#FFFFFF',
  betslipButtonInactive: '#D6D6D6',
  betslipButtonTextInactive: '#8E8E8E',
  betslipIconColor: '#9EAFBE',
  stakeFieldBorderColor: '#6A6D7B',
  stakeFieldTextColor: '#FFFFFF',
  stakeFieldPlaceholderColor: '#9EAFBE',
  betslipPriceBg: '#007B98',
  betslipPriceTextColor: '#1C2331',
  betslipPriceShadow: '#0D1119',
  betslipSelectionText: '#8F8F8F',

  optInputBorder: '#6A6D7B',

  dialogSportAllTitle: '#FFFFFF',
  dialogSportAllDivider: '#ffffff45',
  dialogSportAllBgOne: '#144f85',
  dialogSportAllBgTwo: '#0a2b43',

  myBetsDivider: '#2DA5FF',
  myBetsStatusSuccess: '#A6E732',
  myBetsModalFilterBg: '#035C9F',
  myBetsBtnGroupSelected: '#074B7E',
  myBetsBtnGroupSelectedText: '#FFFFFF',
  myBetsBtnGroupActive: '#035C9F',
};

const myBonus: MyBonusColors = {
  myBonusText: '#FFFFFF',
  myBonusBgCard: '#074b7e',
  myBonusBgCardOff: '#383b44',
  myBonusScrollbarBg: '#1C2331',
  myBonusScrollbarColor: '#007B98',
  myBonusAlertBg: '#1C2331',
};

const profileUser: ProfileUserColors = {
  userAvatarBg: '#074B7E',
  userAvatarText: '#A6E732',
  userProfileBg: '#035C9F',
  userProfileTabText: '#FFFFFF',
  userProfileText: '#9EAFBE',
  userProfileInputBg: '#035C9F',
};

const accordionCustom: AccordionCustomColors = {
  accordionBg: '#307567',
  accordionCaretIcon: '#FFFFFF',
  accordionDividerColor: '#6A6D7B',
};

const feedEvent: FeedEventColors = {
  feedEventOddBg: '#0D1119',
  feedEventEvenBg: '#1C2331',
  feedEventOdd: '#074B7E',
  feedEventEven: '#092C44',
  'active-odds': '#A6E732',
  'active-odds-text': '#092C44',
  marketsBg: '#1C2331',
};

const tables: TablesColors = {
  tableOddBg: '#0d1119',
  tableEvenBg: '#1C2331',
};

const modalCalendarFilter: ModalCalendarFilterColors = {
  datePickerHeaderBg: '#007B98',
  datePickerHeaderTextColor: '#0D1119',
  datePickerBodyBg: '#1C2331',
  datePicketBtn: '#0D1119',
  dataPicektActionsBg: '#1C2331',

  selectionPriceTextColor: '#FFFFFF',
};

const footer: FooterColors = {
  footerBg: '#13517E',
  footerBgO: '#0D395E',
  footerBgDesktop: '#035C9E',
  footerArrowTopBg: '#0D395E',
  footerArrowTopBgMobile: '#035C9E',
  footerIconSocialBg: '#0487D2',
  footerIconSocialBgTwo: '#13517E',

  accumulatorBg: '#0D395E',
};

const modalSearchResult: ModalSearchResultColors = {
  modalSearchBackground: '#0D395E',
  modalSearchBorderColor: '#2DA5FF',
  inputSearchIconClose: '#55748e',
  modalSearchBtnViewMoreColor: '#8A8D9D',
  modalSearchResultTitleBg: '#0D395E',

  listGamesTags: '#A6E732',
  listGamesTextTags: '#0D1119',
};

const snackbar: SnackbarColors = {
  snackBarHomeBGIcon: '#83ab4c',
  snackBarHomeIcon: '#092c44',
  snackBarHomeBg: '#A6E732',
  snackBarText: '#092c44',
};

const alert: AlertColors = {
  alertBgSuccess: '#43BC68',
  alertTextSuccess: '#0D1119',
  alertIconSuccess: '#19602F',
  alertIconBgSuccess: '#35A557',
  alertBgError: '#BC4343',
  alertTextError: '#FFFFFF',
  alertIconError: '#A53838',
  alertIconBgError: '#6F2424',

  'alert-icon-pending': '#80848d',
  'alert-icon-bg-pending': '#676b74',
  'alert-icon-expired': '#3daed5',
  'alert-icon-bg-expired': '#2b7d9a',
  'alert-color-expired': '#10A0D3',
  'alert-icon-reimbursement': '#DCA141',
  'alert-icon-bg-reimbursement': '#AA7C32',
  'alert-color-reimbursement': '#D38810',
};

const casinoFavorite: CasinoFavoriteColors = {
  starColor: '#FFFFFF',
  starBackground: '#A6E732',
};

const lmt: LMTColors = {
  BgBb: '#0D395E',
  BgSrt: '#254d6e',
  HomeColor: '#2385cf',
  BorderHome: '#2385cf',
  ColorAway: '#78b33f',
  BorderAway: '#78b33f',
  SrtAwayBg: '#2DA5FF',
  SrtBaseAway: '#cbf188',
  SrtBaseHome: '#77c5ff',
  SrtBaseColor: '#77c5ff',
  SrtColorAway: '#c5ef7a',
  SrtBaseBg: '#1f4e59',
  BgHoverable: '#1f4e59',
  BgBaseActive: '#275757',
  BgBaseHover: '#275757',
  SrBgBase: '#0d395e',
  BgSrBbBase: '#15425c',
  SrBackgroundActive: '#356653',
  SrtPrimaryHover: '#95d02d',
  SrtPrimary: '#95d02d',
  SrtPrimaryDisabled: '#85b928',
  SrtPrimaryBg: '#c1ee70',
  SrtPrimaryFour: '#69a144',
  SrtPrimaryFive: '#69a144',
  SrtPrimaryOne: '#69a144',
  SrtPrimarySix: '#cbf188',
  PrimaryEightActive: '#95d02d',
  SrtPrimaryNine: '#3b6d51',
  SrtPrimaryTen: '#87c43b',
  SrtPrimaryEleven: '#95d02d',
  SrtPrimaryThirteen: '#15425c',
  SrtBaseTwo: '#c1ee70',
  SrtBaseNeutral: '#2c5373',
  SrtNeutralTwo: '#e7ebef',
  SrtNeutralFour: '#aabac7',
  SrtNeutralSeven: '#aaacaf',
  SrtNeutralFive: '#c3ced7',
  SrtNeutralSix: '#476985',
  SrtNeutralNine: '#1c4568',
  SrtNeutralTen: '#2a5171',
  SrtNeutralEleven: '#6e889e',
  SrtNeutralTwelve: '#9EAFBE',
  SrtAway: '#000000',
  SrtFillInfo: '#0072b1',
  SrtFillWarning: '#faa05a',
  SrtFillError: '#e43b3b',
  SrtFillBlue: '#2579ad',
  SrtFillGreen: '#2cbd00',
  SrtSoccerYellow: '#ffbf00',
  SrtSoccerRed: '#e43b3b',
  SrtSoccerSubst: '#4fbe30',
  SrtFillRlg: '#fdd835',
  SrtFillOrange: '#ffb848',
  SrtSoccerOrange: '#ef6c00',
  SrtRlgFour: '#e93a34',
  SrtRlgFive: '#941d1d',
  SrtPromoGreen: '#51d151',
  SrtPromoTwo: '#1b911b',
  SrtPromoFill: '#0e8094',
  SrtPromoFour: '#0a6cce',
  SrtPromoFive: '#4a9fe4',
  SrtTimeout: '#f5a623',
  SrtPenalty: '#f4a621',
  SrtNegative: '#ac182e',
  SrtFirstLine: '#4b90de',
  SrtMlbRun: '#009900',
  SrtMlbHit: '#f7ab11',
  BgSrtHit: '#00a4ff',
  SrtMlbError: '#d0021b',
};

export const themePixbetColors: ThemeColors = {
  ...primary,
  ...global,
  ...baseLayout,
  ...buttons,
  ...forms,
  ...snackbar,
  ...alert,
  ...footer,
  ...modal,
  ...toggleComponent,
  ...stepRegister,
  ...formRegister,
  ...modalLogin,
  ...weekDaysFilter,
  ...balanceTop,
  ...menuWalletUser,
  ...betslip,
  ...myBonus,
  ...profileUser,
  ...accordionCustom,
  ...feedEvent,
  ...tables,
  ...modalCalendarFilter,
  ...modalSearchResult,
  ...casinoFavorite,
  ...lmt,
};
