export enum Locales {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export type LanguageType = {
  value: string;
  label: string;
  icon?: string;
};

export const LANGUAGES: LanguageType[] = [
  {
    value: Locales.EN,
    label: 'English',
    icon: '🇺🇸',
  },
  {
    value: Locales.ES,
    label: 'Español',
    icon: '🇪🇸',
  },
  {
    value: Locales.PT,
    label: 'Português',
    icon: '🇧🇷',
  },
];

export const LOCALE_KEYS: string[] = [Locales.EN, Locales.ES, Locales.PT];
