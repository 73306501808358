import { Logger } from 'tslog';

/**
 * @description The environments in which the logs can be enabled
 * Other environments will have the logs disabled by default
 */
const ENVIRONMENTS_WITH_LOG_ENABLED = ['development'];

/**
 * @description The main logger of the app
 */
export const appLogger = new Logger({
  name: 'AppLogger',
  type: isLoggingEnabled() ? 'pretty' : 'hidden',
  prettyLogTemplate:
    '{{dd}}/{{mm}}/{{yyyy}} at {{hh}}:{{MM}}:{{ss}}:{{ms}}\t{{logLevelName}}\t[{{name}}]\t',
  prettyErrorTemplate:
    '\n{{errorName}} {{errorMessage}}\nerror stack:\n{{errorStack}}',
  prettyErrorStackTemplate:
    '  • {{fileName}}\t{{method}}\n\t{{filePathWithLine}}',
  prettyErrorParentNamesSeparator: ':',
  prettyErrorLoggerNameDelimiter: '\t',
  stylePrettyLogs: true,
  prettyLogTimeZone: 'UTC',
  prettyLogStyles: {
    logLevelName: {
      '*': ['bold', 'black', 'bgWhiteBright', 'dim'],
      SILLY: ['bold', 'white'],
      TRACE: ['bold', 'whiteBright'],
      DEBUG: ['bold', 'green'],
      INFO: ['bold', 'blue'],
      WARN: ['bold', 'yellow'],
      ERROR: ['bold', 'red'],
      FATAL: ['bold', 'redBright'],
    },
    dateIsoStr: 'white',
    filePathWithLine: 'white',
    name: ['white', 'bold'],
    nameWithDelimiterPrefix: ['white', 'bold'],
    nameWithDelimiterSuffix: ['white', 'bold'],
    errorName: ['bold', 'bgRedBright', 'whiteBright'],
    fileName: ['yellow'],
  },
});

/**
 * Verifies if the logs are enabled in the current environment
 */
export function isLoggingEnabled(): boolean {
  const mode = import.meta.env.MODE.split('.')[1];
  return (
    import.meta.env.SINGLESOFTWARE_ACTIVATE_LOGGER === 'true' &&
    ENVIRONMENTS_WITH_LOG_ENABLED.includes(mode)
  );
}
