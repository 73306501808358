import type { Logger } from 'tslog';

import EventBus, { type IEventBus } from '@/utils/classes/EventBus';
import { appLogger } from '@/utils/logger/LoggerConfig';

type FeedbackOptions = {
  skipSendFeedback?: boolean;
  skipLogger?: boolean;
  skipUserFeedback?: boolean;
  apiErrorMessage?: string;
  isCustomErrorMessage?: boolean;
};

export default class FeedbackService {
  private static BUS: IEventBus = EventBus.getEventBus();
  static logger: Logger<any> = appLogger.getSubLogger({
    name: 'FeedbackService',
  });

  static onError(error: Error, options?: FeedbackOptions) {
    // Logger
    if (!options?.skipLogger) {
      FeedbackService.logger.error(`
        Is custom error message: ${options?.isCustomErrorMessage}
        Api error message: ${options?.apiErrorMessage || false}
        Stack: \n ${error.stack}
      `);
    }

    if (!options?.skipSendFeedback) {
      /* Send error to monitoring API */
    }

    if (options?.skipUserFeedback) return;
    FeedbackService.BUS.emit('error', error);
  }

  static onWarn(warnMsg: string, options?: FeedbackOptions) {
    // warn handler
    if (!options?.skipLogger) {
      FeedbackService.logger.warn(warnMsg);
    }

    // Implement registry of warnings

    if (options?.skipUserFeedback) return;

    FeedbackService.BUS.emit('warn', warnMsg);
  }

  static onInfo(infoMsg: string, options?: FeedbackOptions) {
    // info handler
    if (!options?.skipLogger) {
      FeedbackService.logger.info(infoMsg);
    }

    // Implement registry of info

    if (options?.skipUserFeedback) return;

    FeedbackService.BUS.emit('info', infoMsg);
  }

  static onSuccess(successMsg: string) {
    // Implement registry of success
    FeedbackService.BUS.emit('success', successMsg);
  }
}
